<template>
  <div>
    <card type="default" header-classes="bg-transparent" v-loading="loaders.card">
      <message-header @pushNotificationSent="changePage(1)"/>
      <message-body :messages="response.messages"/>
      <message-footer :pagination="response.pagination" @changePage="changePage"
                      @changedPagination="changedPagination"/>
    </card>
  </div>
</template>

<script>
import MessageHeader from "@/views/Components/Patients/PatientDetail/MessageHeader";
import MessageBody from "@/views/Components/Patients/PatientDetail/MessageBody";
import MessageFooter from "@/views/Components/Patients/PatientDetail/MessageFooter";

export default {
  name: "PatientDetailMessages",
  components: {
    MessageFooter,
    MessageBody,
    MessageHeader
  },
  data() {
    return {
      loaders: {
        card: false,
      },
      request: {
        patient_id: this.$route.params.id,
        pagination: 2,
      },
      response: {
        messages: [],
        pagination: {},
      }
    }
  },
  methods: {
    changePage(page) {
      this.getMessages(page);
    },

    changedPagination(pagination) {
      this.request.pagination = pagination;
      this.getMessages(1);
    },

    getMessages(page) {
      let vm = this;
      this.loaders.card = true;
      axios.get(`${this.$store.getters.getBaseUrl}/api/patient-messages?page=${page}&patient_id=${this.request.patient_id}&pagination=${this.request.pagination}`)
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.messages = response.data.data.data;
          vm.response.pagination = response.data.data;
        }).catch(error => {
        console.log(error);
      }).finally(() => {
        vm.loaders.card = false;
      })
    }
  },
  mounted() {
    this.getMessages(1);
  }
}
</script>

<style scoped>

</style>
