<template>
  <div slot="header" class="row align-items-center" style="justify-content: space-between">
    <push-notification :notificationModal="notificationModal" @closeNotificationModal="notificationModal=$event" @pushNotificationSent="$emit('pushNotificationSent')"/>
    <h4 class="text-uppercase ls-1 mb-2 ml-4 font-weight-700 text-uppercase">Messages</h4>
    <base-button @click="notificationModal=true" class="mr-4 pl-4 pl-4 btn-secondary-action font-weight-normal">Message</base-button>
  </div>
</template>

<script>
import PushNotification from "@/views/Components/Modals/PushNotification";
export default {
  name: "MessageHeader",
  components: {
    PushNotification,
  },
  data() {
    return {
      notificationModal: false,
    }
  }
}
</script>

<style scoped>

</style>
