<template>
  <div class="scrollable height-fixed">
    <div class="notes pt--2 pl-2 pr-2 pb-3">
      <div class="message-body pl-4 pr-4 pt-3 pb-3 mt-3" v-for="message in messages">
        <p class="message">
          {{ message.msg }}
        </p>

        <div class="message-footer">
          <p class="date">{{ $options.filters.globalDateFormat(message.created) }}</p>
          <p class="time-date-separator"></p>
          <p class="time ml-3">{{ $options.filters.time(message.created) }}</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageBody",
  props: ['messages'],
  data() {
    return {}
  },
}
</script>

<style scoped>

.message-body {
  background: #F6F9FC 0 0 no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.message {
  text-align: left;
  font: normal normal normal 14px/24px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  opacity: 1;
  white-space: pre-line;
}

.message-footer p {
  display: inline-block;
}

.message-footer p, .added-by {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #8898AA;
  opacity: 1;
}

.scrollable{
  overflow-y: auto;
}

.height-fixed{
  max-height: 268px;
}

</style>
