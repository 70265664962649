<template>
  <!-- Notification Modal -->
  <modal v-loading="loaders.notification" :show.sync="modalVisibility">
    <!-- Notification Modal Header -->
    <template slot="header">
      <h5 class="modal-title" id="SendMessageModal">Message</h5>
    </template>
    <!-- Notification Modal Header Finished -->

    <!-- Notification Modal Body -->
    <template slot="default">
      <p class="message-modal-text mb-4">
        This is a push notification. Do not send anything that could be considered Protected Health Information.
        Patients cannot respond to these messages.
      </p>
      <base-input label="Message">
        <textarea
          v-model="request.notification"
          class="form-control"
          id="notification-message"
          rows="4">
        </textarea>
      </base-input>
    </template>
    <!-- Notification Modal Body Finished -->

    <!-- Notification Modal Footer -->
    <template slot="footer">
      <div class="mt--5">
        <base-button type="secondary" class="btn-gray" @click="modalVisibility=false">Cancel</base-button>
        <base-button class="btn-primary-action" type="success" @click="sendNotification">Send Message</base-button>
      </div>
    </template>
    <!-- Notification Modal Footer Finished -->
  </modal>
  <!-- Notification Modal Finished -->
</template>

<script>
export default {
  name: "Message",
  props: {
    notificationModal: Boolean,
    id: [String,Number]
  },
  data() {
    return {
      modalVisibility: false,
      loaders: {
        notification: false,
      },
      request: {
        notification: '',
        patient_id: +this.$route.params.id,
      }
    }
  },
  watch: {
    id(newVal) {
      if(newVal){
        this.request.patient_id = newVal;
      }
    },
    notificationModal(newVal) {
      this.modalVisibility = newVal
    },
    modalVisibility(newVal) {
      if(!newVal) this.request.notification = '';
      this.$emit('closeNotificationModal', newVal)
    }
  },
  methods: {
    sendNotification() {
      let vm = this;
      vm.loaders.notification = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/nutrition/message`,
        vm.request,
      ).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.request.notification = '';
        this.$emit('pushNotificationSent',true);
        vm.$notify.success({
          title: 'Notification',
          message: 'Message Sent.'
        })
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Notification',
          message: message
        })
      }).finally(() => {
        vm.loaders.notification = false;
        vm.modalVisibility = false;
      });
    },
  },
  mounted() {
    this.modalVisibility = this.notificationModal;
  }
}
</script>

<style scoped>

</style>
